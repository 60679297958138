import React, { Component } from 'react'
import {
    Modal,
    Typography,
    Table,
    Spin,
    Form,
    Input,
    Button,
    Select,
    Space,
    Row,
    Col,
    Layout,
    Card,
    message,
    Tooltip,
} from 'antd'
import { withRouter } from 'react-router'
import {
    HOME_URL,
    ANALYZE_URL,
    BACKEND_URL,
    MISSING_METADATA_STR,
} from '../../shared/constants'
import white_logo from '../../assets/default_img.png'
import _, { times } from 'lodash'
import {
    LineChartOutlined,
    DownloadOutlined,
    TableOutlined,
    SearchOutlined,
    EditOutlined,
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import {
    ENTITY_TARGET,
    setDashboardFiltersAndRefresh,
    setAnalyzeEntityAndLoadData,
    reloadEntites,
} from '../../features/entity/entityActions'
import { connect } from 'react-redux'
import apiService from '../../features/apiService'
import AdminModal from './AdminModal'
import AmplifyService from '../../AmplifyService'
import { setToken, logout } from '../../features/auth/authActions'
import LoginForm from './LoginForm'
import './loginLayout.scss'
import { InfoCircleFilled } from '@ant-design/icons'
import EntityModal from '../../shared/components/EntityModal'
import ForgotPasswordForm from './ForgotPasswordForm'
import PasswordResetForm from './PasswordResetForm'
import GAService from '../../GAService'
import BLPInstance from '../../BloombergService'

export const getUrlParam = (param) => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams.get(param)
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const { Content } = Layout
const { Search } = Input;

class Home extends Component {
    constructor(props) {
        super(props);
        this.setEntityModalData = this.setEntityModalData.bind(this);

        this.nameFilterSelectedKeys = null;
        this.nameFilterSetSelectedKeys = null;
        this.nameFilterConfirm = null;

        this.topRef = React.createRef();

        this.updateFilterUI = function() {
            const { entities } = this.props
            const { filters } = entities 
            const table = document.getElementsByTagName('table')[0];
    
            if (table) {
                const tableHeader = table.getElementsByTagName('thead')[0];
                if (tableHeader) {
                    const headerRow = tableHeader.childNodes[0];
        
                    if (!!filters.name) {
                        headerRow.childNodes[0].classList.add('th-filter');
                    }
                    else {
                        headerRow.childNodes[0].classList.remove('th-filter');
                    }
        
                    if (!!filters.entity_type) {
                        headerRow.childNodes[1].classList.add('th-filter');
                    }
                    else {
                        headerRow.childNodes[1].classList.remove('th-filter');
                    }
        
                    if (!!filters.authorizer) {
                        headerRow.childNodes[4].classList.add('th-filter');
                    }
                    else {
                        headerRow.childNodes[4].classList.remove('th-filter');
                    }
        
                    if (!!filters.debt_issuer) {
                        headerRow.childNodes[5].classList.add('th-filter');
                    }
                    else {
                        headerRow.childNodes[5].classList.remove('th-filter');
                    }                
        
                    if (!!filters.city) {
                        headerRow.childNodes[6].classList.add('th-filter');
                    }
                    else {
                        headerRow.childNodes[6].classList.remove('th-filter');
                    }
        
                    if (!!filters.state) {
                        headerRow.childNodes[7].classList.add('th-filter');
                    }
                    else {
                        headerRow.childNodes[7].classList.remove('th-filter');
                    }
        
                    if (filters.enrollment && filters.enrollment.length > 0) {
                        headerRow.childNodes[8].classList.add('th-filter');
                    }
                    else {
                        headerRow.childNodes[8].classList.remove('th-filter');
                    }
                }
            }
        }
    }

    state = {
        homeSearch: '',
        entityModalData: null,
        searchText: '',
        searchedColumn: '',
        forgotPassword: false,
        resetPassword: false,
        forgotPasswordUsername: '',
        lockTableScroll: false
    }

    

    componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let username = params['forgotpassword'];
        if (username && username.length > 0) {
            if (this.props.token && this.props.logout) {
                this.props.logout(this.props.token)
                .then((resp) => {
                    this.setState({forgotPassword: true, forgotPasswordUsername: username});
                })

            }
            else {
                this.setState({forgotPassword: true, forgotPasswordUsername: username});
            }
        }

        setTimeout(() => {
            this.setState({
                homeSearch: this.initialHomeSearch
            });

            this.updateFilterUI();

        }, 2000);
    }

    componentDidUpdate() {
        let that = this;
        setTimeout(function() {
            that.updateFilterUI();
        }, 1000);
    }

    getRegistrationEmail = () => {
        const param = getUrlParam('completeRegistration')
        if (!param) {
            return null
        }
        const decoded = atob(param)
        const email = decoded.split('email=')[1]
        return email
    }

    getRegistrationToken = () => {
        const param = getUrlParam('completeRegistration')
        if (!param) {
            return null
        }
        const decoded = atob(param)
        const token = decoded.split('token=')[1].split(';')[0]
        return token
    }

    setEntityModalData(data) {
        this.setState({entityModalData: data})
    }

    getSearchBar() {
        return (
            <>
                <div style={{ height: 15 }} />
                <Search
                    style={{
                        width: '99%',
                        marginLeft: 5,
                    }}
                    placeholder={'Enter school name'}
                    allowClear
                    ref={(node) => {
                        this.searchBox = node
                    }}
                    value={this.state.homeSearch ? this.state.homeSearch[0] : ''}
                    onChange={(searchValue) => {
                        //TODO: debounce
                        if (this.nameFilterSetSelectedKeys) {
                            this.nameFilterSetSelectedKeys([searchValue.target.value]);
                            if (searchValue.target.value.length === 0) {
                                this.handleSearch([''], this.nameFilterConfirm, 'name', false);
                            }
                        }

                        this.setState({homeSearch: [searchValue.target.value], lockTableScroll: true})
                    }}
                    onSearch={(value) => {
                        GAService.search('Home');
                        this.handleSearch([value], this.nameFilterConfirm, "name", false);
                    }}
                />
                
                <div style={{ height: 15 }} />
            </>
        )
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => 
        {
            if (dataIndex === 'name') {
                this.nameFilterSetSelectedKeys = setSelectedKeys;
                this.nameFilterSelectedKeys = selectedKeys;
                this.initialHomeSearch = selectedKeys;
                this.nameFilterConfirm = confirm;
            }
            
            return (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => {
                        if (dataIndex === 'name') {
                            this.setState({homeSearch: selectedKeys});
                        }
                        this.handleSearch(selectedKeys, confirm, dataIndex, true);
                    }}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            this.handleSearch(selectedKeys, confirm, dataIndex, true)
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() =>
                            this.handleReset(clearFilters, dataIndex)
                        }
                        size='small'
                        style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        )},
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100)
            }
        },
        render: (text) => {
            let cursorStyle = dataIndex === 'name' ? 'pointer' : 'auto';
            if (dataIndex === 'authorizer') {
                if (text) {
                    let authorizers = text.split(',');
                    if (authorizers && authorizers.length > 0) {
                        let filtered = [];
                        let unfiltered = [];
                        authorizers.forEach((auth) => {
                            if (auth.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) {
                                filtered.push(auth);
                            }
                            else {
                                unfiltered.push(auth);
                            }
                        });
                        let combined = filtered.concat(unfiltered);
                        text = combined.join(',');
                        if (text.length > 60) {
                            text = text.substring(0, 57);
                            text += '...';
                        }
                    }
                }
            }
            return this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : text ? (
                <span style={{cursor: cursorStyle}}>{text}</span>
            ) : (
                MISSING_METADATA_STR
            )
        }
    })

    handleSearch = (selectedKeys, confirm, dataIndex, toGA) => {
        confirm()
        if (toGA) {
            GAService.filter("Home", dataIndex);
        }

        const searchText = selectedKeys[0]
        this.setState({
            searchText,
            searchedColumn: dataIndex,
        })

        if (searchText == null || searchText.length == 0) {
            this.handleReset(null, 'name');
        }
    }

    handleReset = (clearFilters, dataIndex, handleClear) => {
        const { entities } = this.props
        const { filters } = entities
        var updatedFilters = {
            ...filters,
        }

        if (clearFilters) {
            clearFilters();
        }

        this.setState({ searchText: '' })
        if (dataIndex === 'name') {
            this.setState({homeSearch: ''});
        }
        updatedFilters[dataIndex] = null
        this.setState({lockTableScroll: false })
        this.props.setDashboardFiltersAndRefresh(updatedFilters)
    }

    handleTableChange = (pagination, filters, sorter) => {
        var updatedFilters = {
            ...filters,
            limit: pagination.pageSize,
            currentPage: pagination.current,
            offset: (pagination.current - 1) * pagination.pageSize,
        }
        if (sorter && sorter.field && sorter.order) {
            const sortName = `sort_${sorter.field}`
            updatedFilters = {
                ...updatedFilters,
                [sortName]: sorter.order,
            }
        }
        this.setState({ lockTableScroll: false })
        this.props.setDashboardFiltersAndRefresh(updatedFilters)
    }

    setForgotPassword = (value) => {
        this.setState({forgotPassword: value});
    }

    setResetPassword = (value) => {
        if (value === true) {
            this.setState({forgotPassword: false, resetPassword: true});
        }
        else {
            this.setState({forgotPassword: true, resetPassword: false});
        }
    }

    handleLogin = async (e) => {
        let email = this.getRegistrationEmail()

        if (!email) {
            let res = await AmplifyService.userSignIn(e.username, e.password)
            if (res.code === 'NotAuthorizedException' || res.code === 'NetworkError' 
                || !res.signInUserSession || !res.signInUserSession.accessToken) {
                message.warning(
                    'login failed, please check your credentials or network connection',
                    2
                )
                GAService.login(false);
            } else {
                let testAuthRes = await apiService.getMe(
                    res.signInUserSession.accessToken.jwtToken
                )
                if (
                    testAuthRes.response &&
                    testAuthRes.response.status === 403
                ) {
                    message.warning(
                        'login failed, please contact system administrator',
                        2
                    )
                    this.props.setToken({
                        authToken: null,
                        refreshToken: null,
                    })

                    GAService.login(false);

                } else {
                    this.props.setToken({
                        authToken: res.signInUserSession.accessToken.jwtToken,
                        refreshToken: res.signInUserSession.refreshToken.token,
                    })

                    GAService.login(true);
                }
            }
        } else {
            let token = this.getRegistrationToken()
            //console.log(token);
            try {
                let verifyResult = await apiService.verifyRegistration(
                    email,
                    token
                )

                if (verifyResult.status === 200) {
                    let signupRes = await AmplifyService.userSignUp(
                        e.username,
                        e.password
                    )

                    //GAService.registration(true);
                    await apiService.finishRegistration(
                        // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-up
                        // The Auth.signUp promise returns a data object of type ISignUpResult with
                        // a CognitoUser. CognitoUser contains a userSub which is a unique identifier 
                        // of the authenticated user; the userSub is not the same as the username.
                        // the userSub field does include the username though...
                        //signupRes.signInUserSession.accessToken.jwtToken,
                        signupRes.userSub,
                        {
                            email: e.username,
                            token: token,
                        }
                    )

                    // we don't get the access token after 
                    // confirming the registration. need to
                    // clear the tokens and go to the homepage
                    this.props.setToken({
                        authToken: null,
                        refreshToken: null,
                    });
                    //console.log(HOME_URL);
                    this.props.history.push(HOME_URL);
                } else {
                    //GAService.registration(false);
                    message.warning(
                        'registration failed, please verify this is the correctly registreded email',
                        2
                    )
                    this.props.setToken({
                        authToken: null,
                        refreshToken: null,
                    })
                    return
                }
            } catch (ex) {
                let res = await AmplifyService.userSignIn(
                    e.username,
                    e.password
                )

                if (res.code === 'NotAuthorizedException') {
                    message.warning(
                        'registration failed, please verify this is the correctly registreded email',
                        2
                    );

                    GAService.registration(false);
                } else {
                    await apiService.finishRegistration(
                        res.signInUserSession.accessToken.jwtToken,
                        {
                            email: e.username,
                            token: token,
                        }
                    )

                    GAService.registration(true);

                    this.props.setToken({
                        authToken: res.signInUserSession.accessToken.jwtToken,
                        refreshToken: res.signInUserSession.refreshToken.token,
                    })
                }
            }
        }
    }

    handleForgotPassword = async (values) => {
        let email = values["username"];
        this.setState({forgotPasswordUsername: email});

        GAService.forgotPassword();
        let res = await AmplifyService.forgotPassword(email);
        this.setResetPassword(true);
    }

    handleResetPassword = async (values) => {
        let username = values['username'];
        let code = values['code'];
        let password = values['password'];

        GAService.resetPassword();
        let res = await AmplifyService.resetPassword(username, code, password);
        return res;
    }

    isAdmin() {
        return this.props && this.props.user && this.props.user.is_admin
    }

    getStateFilters() {
        return this.props.entities &&
            this.props.entities.all &&
            this.props.entities.all.active_states
            ? this.props.entities.all.active_states.map((s) => {
                  return { text: s.name, value: s.abbreviation }
              })
            : []
    }

    clearAllFilters(e) {
        var emptiedFilters = {}
        Object.keys(this.props.entities.filters).forEach(
            (k) => (emptiedFilters[k] = null)
        )
        this.setState({ searchText: '', searchedColumn: '',  homeSearch: '', lockTableScroll: false })
        this.props.setDashboardFiltersAndRefresh(emptiedFilters)
    }

    getStateOptions() {
        const { entities } = this.props
        const { all } = entities
        const { active_states } = all
        if (!active_states) {
            return <></>
        }
        return active_states.map((s) => (
            <Select.Option key={s.abbreviation} value={s.abbreviation}>
                {s.abbreviation}
            </Select.Option>
        ))
    }

    getObligatedGroupModalData() {
        return this.state.obligatedGroupModal
    }

    getCmoModalData() {
        return this.state.cmoModal
    }

    getSchoolData() {
        return this.state.schoolModal
    }

    getCharterData() {
        return this.state.charterModal
    }

    handleScroll() {
        if (!this.props.entities.dashboardLoading && !this.state.lockTableScroll) {
            
            if (this.props.entities.analyzeEntity) {
                let topRefPtr = this.topRef.current;
                setTimeout(function() {
                    let scrolled = false;
                    let row = document.querySelector('.scroll-row');
                    if (row) {
                        scrolled = true;
                        row.scrollIntoView();
                    }
                    else if (topRefPtr) {
                        scrolled = true;
                        topRefPtr.scrollIntoView();
                    }

                    if (!scrolled && this.topRef?.current) {
                        this.topRef.current.scrollIntoView();
                    }
                }, 500);
            }
            else if (this.topRef.current) {
                this.topRef.current.scrollIntoView();
            }
        }
    }

    render() {
        const { Title } = Typography
        const { entityModalData } = this.state
        const { token, entities, reloadEntites } = this.props
        const { all, filters } = entities
        
        if (token && (!all || all.length == 0)) {
            reloadEntites(filters)
            return <Spin size='large' />
        }

        // Also do the last highlighting
        if (!this.state.searchText) {
            const { filters } = entities
            if (filters.name && filters.name[0] && filters.name[0].length > 0) {
                this.setState({
                    searchText: filters.name[0],
                    searchedColumn: 'name',
                })
            } else if (filters.city) {
                this.setState({
                    searchText: filters.city[0],
                    searchedColumn: 'city',
                })
            }
        }

        const passwordVerification = [
            {
                required: true,
                message: 'Please input your password!',
            }
        ];

        const createPasswordVerification = [
            {
                required: true,
                message: 'Please input your password!',
            },

            {
                validator(_, value) {
                    if (value && value.length >= 6 && value.length <= 99) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Password must be between 6 and 99 characters long'
                        )
                    )
                },
            },
            {
                validator(_, value) {
                    const re = /(?=.*[a-z])/
                    if (value && re.test(value)) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Password must contain at least 1 lower case letter'
                        )
                    )
                },
            },
            {
                validator(_, value) {
                    const re = /(?=.*[A-Z])/
                    if (value && re.test(value)) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Password must contain at least 1 upper case letter'
                        )
                    )
                },
            },
            {
                validator(_, value) {
                    const re = /\d/
                    if (value && re.test(value)) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error('Password must contain at least 1 number')
                    )
                },
            },
            {
                validator(_, value) {
                    //^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + -
                    const re = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
                    if (value && re.test(value)) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Password must contain at least 1 special character'
                        )
                    )
                },
            },
        ];

        const matchPasswordVerification = [
            {
                required: true,
                message:
                    'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (
                        !value ||
                        getFieldValue('password') ===
                            value
                    ) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'The two passwords that you entered do not match!'
                        )
                    )
                },
            }),
        ];

        const columns = [
            {
                title: 'Name',
                //className: !!filters.name ? 'th-filter' : null,
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                defaultFilteredValue: filters.name,
                filteredValue: filters.name
            },
            {
                title: 'Entity Type',
                dataIndex: 'charter_type',
                key: 'entity_type',
                filters: [
                    {
                        text: 'CMO',
                        value: 'CMO',
                    },
                    {
                        text: 'Obligated Group',
                        value: 'Obligated Group',
                    },
                    {
                        text: 'Freestanding',
                        value: 'Freestanding',
                    },
                    {
                        text: 'CMO Member',
                        value: 'CMO Member',
                    },
                    {
                        text: 'Obligated Group Member',
                        value: 'Obligated Group Member',
                    },
                    {
                        text: 'N/A',
                        value: 'N/A',
                    },
                ],
                defaultFilteredValue: filters.entity_type,
                filteredValue: filters.entity_type,
            },
            {
                title: 'Number of Schools',
                dataIndex: 'num_schools',
                key: 'num_schools',
                render: (value, record) => {
                    return (record.charter_type === 'CMO' || record.charter_type == 'Obligated Group') ? (value ? value : MISSING_METADATA_STR) : '1'
                },
            },
            {
                title: 'Charter Expiration',
                dataIndex: 'charter_expire',
                key: 'charter_expire',
                render: (value, record) => {
                    return value ? value : MISSING_METADATA_STR
                },
                sorter: (a, b) => {
                    return a.charter_expire - b.charter_expire
                },
                sortOrder: filters.sort_charter_expire,
            },
            {
                title: 'Authorizer',
                dataIndex: 'authorizer',
                key: 'authorizer',
                ...this.getColumnSearchProps('authorizer'),
                defaultFilteredValue: filters.authorizer,
                filteredValue: filters.authorizer,
            },
            {
                title: 'Debt Issuer',
                dataIndex: 'debt_issuer',
                key: 'debt_issuer',
                filters: [
                    {
                        text: 'Yes',
                        value: 'Yes',
                    },
                    {
                        text: 'No',
                        value: 'No',
                    },
                ],
                defaultFilteredValue: filters.debt_issuer,
                filteredValue: filters.debt_issuer,
                render: (value, record) => {
                    return value ? 'Yes' : 'No'
                },
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                ...this.getColumnSearchProps('city'),
                defaultFilteredValue: filters.city,
                filteredValue: filters.city,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                filters: this.getStateFilters(),
                defaultFilteredValue: filters.state,
                filteredValue: filters.state,
            },
            {
                title: 'Enrollment',
                dataIndex: 'enrollment',
                key: 'enrollment',
                filters: [
                    {
                        text: '0 - 500',
                        value: [0, 500],
                    },
                    {
                        text: '501 - 1000',
                        value: [501, 1000],
                    },
                    {
                        text: '1000+',
                        value: [1001, 10000000000000],
                    },
                ],
                render: (value, record) => {
                    return value ? value : MISSING_METADATA_STR
                },
                defaultFilteredValue: filters.enrollment,
                filteredValue: filters.enrollment,
            },
        ]
        if (this.isAdmin()) {
            columns.push({
                title: '',
                key: 'edit',
                render: (_, record) => {
                    return (
                        <Button
                            onClick={(ev) => {
                                // stop event from propagating to table row level 
                                // so the details dialog won't show up
                                ev.stopPropagation();
                                if (record.charter_type === 'Obligated Group') {
                                    // TODO open obligated group edit
                                    apiService
                                        .getObligatedGroup(token, record.obligated_group_id)
                                        .then((resp) => {
                                            if (resp) {
                                                this.setState({
                                                    obligatedGroupModal: resp.data,
                                                })
                                            }
                                        })
                                }
                                else if (record.charter_type === 'CMO') {
                                    apiService
                                        .getCmo(token, record.cmo_id)
                                        .then((resp) => {
                                            if (resp) {
                                                this.setState({
                                                    cmoModal: resp.data,
                                                })
                                            }
                                        })
                                } else {
                                    Promise.all([
                                        apiService.getSchool(
                                            token,
                                            record.school_id
                                        ),
                                        apiService.getCharter(
                                            token,
                                            record.school_id
                                        ),
                                    ]).then((resps) => {
                                        if (resps) {
                                            this.setState({
                                                schoolModal: resps[0]
                                                    ? resps[0].data
                                                    : null,
                                                charterModal: resps[1]
                                                    ? resps[1].data
                                                    : null,
                                            })
                                        }
                                    })
                                }
                            }}>
                            <EditOutlined />
                        </Button>
                    )
                },
            })
        }
        
        if (!BLPInstance.isBloomberg(this.props)) {
            columns.push({
                title: '',
                key: 'export',
                render: (_, record) => {
                    return (
                        <Button
                            className='export-button'
                            style={{marginTop: 0}}
                            onClick={(ev) => {
                                // stop event from propagating to table row level 
                                // so the details dialog won't show up
                                ev.stopPropagation();
                                GAService.exportReport('Home', record.charter_type);

                                let apiTarget = (record.charter_type === 'Obligated Group') ? 'obligated_groups' 
                                : ((record.charter_type == 'CMO') ? 'cmos' : 'schools');
                                let apiId = (record.charter_type === 'Obligated Group') ? record.obligated_group_id 
                                : ((record.charter_type == 'CMO') ? record.cmo_id : record.school_id);
                                window.open(`${BACKEND_URL}/${apiTarget}/${apiId}/export?token=${this.props.token}&format=xlsx`, '_blank');
                            }
                            }>Export</Button>
                    )
                }
            });
        }
        

        return (
            <>
                {token && all ? (
                    <>
                        <Title level={2}>
                            {' '}
                            <div className="ant-row" ref={this.topRef} style={{alignItems: 'center'}}>
                                <TableOutlined />&nbsp;Browse
                                <Tooltip title="Get an overview of schools, CMOs, and Obligated Groups. Click individual names to see detailed information. Click the Export button to export an Excel report of the school (not available on the Bloomberg Terminal). Use the table header filters/sorters to quickly find entities based on name, type, location etc."><InfoCircleFilled style={{position: 'relative', marginLeft: '3px', top: '-2px', fontSize: '1.0rem'}} /></Tooltip>
                            </div>
                        </Title>
                        <Row
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                            }}>
                            <Col span={18}>{this.getSearchBar()}</Col>
                            <Col span={1}>
                                <Button 
                                    onClick={(e) => this.clearAllFilters(e)}>
                                    Clear Filters
                                </Button>
                            </Col>
                        </Row>
                        <Table
                            rowClassName={(record, index) => {
                                let className = '';
                                if (this.props.entities.analyzeEntity && this.props.entities.analyzeEntity.name && record.name) {
                                    if (record.name.toUpperCase() === this.props.entities.analyzeEntity.name.toUpperCase()) {
                                        className = 'scroll-row';
                                    }
                                }
                                return index % 2 === 0 ? (className.length > 0 ? className + ' alternate-row' : 'alternate-row') : className
                            }}
                            className='sip-table'
                            style={{ marginTop: 10 }}
                            columns={columns}
                            rowKey='id'
                            dataSource={this.props.entities.all.items}
                            pagination={{
                                defaultCurrent: this.props.entities.filters
                                    .currentPage
                                    ? this.props.entities.filters.currentPage
                                    : 1,
                                current: this.props.entities.filters.currentPage
                                    ? this.props.entities.filters.currentPage
                                    : 1,
                                total: this.props.entities.all.total,
                                defaultPageSize: this.props.entities.filters
                                    .limit
                                    ? this.props.entities.filters.limit
                                    : 10,
                                pageSize: this.props.entities.filters.limit
                                    ? this.props.entities.filters.limit
                                    : 10,
                            }}
                            onRow={(record, rowIndex) => {
                                let myRecord = record;
                                if (rowIndex === 0) {
                                    this.handleScroll();
                                }

                                return {
                                    onClick: (event) => {
                                        GAService.openModal("Home", "Details", myRecord.charter_type);
                                        this.setState({
                                            entityModalData: record,
                                        })
                                    }, // click row
                                }
                            }}
                            onChange={this.handleTableChange}
                        />
                        <Space />
                        {entityModalData && <EntityModal {...this.props} entityModalData={entityModalData} setEntityModalData={this.setEntityModalData} />}
                        <AdminModal
                            token={this.props.token}
                            onClose={() => {
                                this.props.reloadEntites(filters)
                                this.setState({
                                    cmoModal: null,
                                    obligatedGroupModal: null,
                                    schoolModal: null,
                                    charterModal: null,
                                    entityModalData: null,
                                })
                            }}
                            isAdmin={() => this.isAdmin()}
                            loadCmo={() => this.getCmoModalData()}
                            loadObligatedGroup={() => this.getObligatedGroupModalData()}
                            loadSchool={() => this.getSchoolData()}
                            loadCharter={() => this.getCharterData()}
                            getStateOptions={() => this.getStateOptions()}
                        />
                    </>
                ) : (
                    <Layout className='login_layout'>
                        <Content className='content'>
                            <Row
                                className='content_row'
                                justify='center'
                                align='middle'>
                                <Card className='login_card'>
                                    <Row
                                        className='content_row'
                                        justify='center'
                                        align='middle'>
                                        <img
                                            alt={'sip logo'}
                                            className='login_logo_img'
                                            src={white_logo}
                                        />
                                    </Row>
                                    {this.state.forgotPassword === false && this.state.resetPassword === false &&                                        
                                        <LoginForm
                                            handleLogin={this.handleLogin}
                                            setForgotPassword={this.setForgotPassword}
                                            singleVerification = {passwordVerification}
                                            createPasswordVerification={createPasswordVerification}
                                            matchPasswordVerification={matchPasswordVerification}
                                            registrationEmail={this.getRegistrationEmail()}
                                        /> } 
                                    { this.state.forgotPassword === true && this.state.resetPassword === false && (<ForgotPasswordForm 
                                            setForgotPassword={this.setForgotPassword}
                                            registrationEmail = {this.state.forgotPasswordUsername}
                                            handleForgotPassword={this.handleForgotPassword}
                                        />)
                                    }

                                    { this.state.forgotPassword === false && this.state.resetPassword === true && (<PasswordResetForm 
                                            registrationEmail={this.state.forgotPasswordUsername}
                                            setResetPassword={this.setResetPassword}
                                            setResetSuccess={() => {setTimeout(() => {this.setState({forgotPassword: false, resetPassword: false})}, 3000)}}
                                            passwordVerification={createPasswordVerification}
                                            matchPasswordVerification={matchPasswordVerification}
                                            handleResetPassword={this.handleResetPassword}
                                        />)
                                    }
                                    
                                </Card>
                            </Row>
                        </Content>
                    </Layout>
                )}
            </>
        )
    }
}

const mapStateToProps = ({ auth: { authToken }, entities, ...rest }) => {
    return {
        entities,
        authToken,
    }
}

export default connect(mapStateToProps, {
    setAnalyzeEntityAndLoadData,
    setDashboardFiltersAndRefresh,
    reloadEntites,
    setToken,
    logout,
})(withRouter(Home))
