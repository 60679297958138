import React, { Component } from 'react'

import { Router } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import favicon from './assets/favicon.png'
import { MainAppContainer } from './shared/components'
import './shared/styles/main.scss'
import { persistor, store } from './features/persistentStore'
import { PersistGate } from 'redux-persist/integration/react'
import 'antd/dist/antd.css'
import ReactGA from "react-ga4";
import { GA_TRACKID, GA_DEBUG } from './shared/constants'
import GAService from './GAService'

ReactGA.initialize(GA_TRACKID);

const history = createBrowserHistory()
history.listen((location) => {
    GAService.pageView(location.pathname + location.search);
});

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router history={history}>
                        <Helmet>
                            <meta charSet='utf-8' />
                            <title>SIP Database</title>
                            <link
                                rel='shortcut icon'
                                id='favicon'
                                href={favicon}></link>
                        </Helmet>
                        <MainAppContainer />
                    </Router>
                </PersistGate>
            </Provider>
        )
    }
}

export default App
